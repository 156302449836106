import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthorizeState {
  isReadUserList: boolean;
  isReadAuthorizeSetting: boolean;
  isReadCompanySetting: boolean;
}

const initialState = {
  isReadUserList: true,
  isReadAuthorizeSetting: true,
  isReadCompanySetting: true,
};

export const authorizeSlice = createSlice({
  name: 'authorizeSlice',
  initialState: initialState as AuthorizeState,
  reducers: {
    setIsReadUserList: (state, action: PayloadAction<boolean>) => {
      state.isReadUserList = action.payload;
    },
    setIsReadAuthorizeSetting: (state, action: PayloadAction<boolean>) => {
      state.isReadAuthorizeSetting = action.payload;
    },
    setIsReadCompanySetting: (state, action: PayloadAction<boolean>) => {
      state.isReadCompanySetting = action.payload;
    },
  },
});
