import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CompanyInfo, getCompanyInfo, getCurrentAllCompany, setCurrentCompany, setIsLoginSubsidiary, logout } from '../../../redux/login/LoginAction';
import { RootState } from '../../../redux/rootReducer';
import { I18n } from '../../../utils/I18n';
import MainPage from '../../MainPage';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import DarkButton from '../../../components/DarkButton';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';
import { persistor } from '../../../redux/store';

export default function SelectCompany() {
  const dispatch = useDispatch();
  const { currentAllCompany, currentCompany, user } = useSelector((state: RootState) => state.auth, shallowEqual);
  const history = useHistory();
  const classes = useStyles();

  const handleSetCurrentCompany = (company: CompanyInfo, isSubsidiary: boolean): void => {
    dispatch(setCurrentCompany(company));
    dispatch(setIsLoginSubsidiary(isSubsidiary));
    history.push('/');
  };

  const handleCompanyInfo = (result: any) => {
    const { resultCode, data } = result;
    if (resultCode === 'SUCCESS') handleSetCurrentCompany(data, false);
  };

  const handleLogout = () => {
    persistor.purge();
    dispatch(logout());
    history.go(0);
  };

  useEffect(() => {
    if (!user?.authList?.includes('ShowCompanySubsiadary')) {
      dispatch(getCompanyInfo(false, currentCompany, handleCompanyInfo));
    } else if (!currentCompany?.id) {
      dispatch(getCurrentAllCompany());
    }
  }, [user]);

  useEffect(() => {
    if (currentAllCompany?.length <= 1) handleSetCurrentCompany(currentAllCompany[0], false);
  }, [currentAllCompany]);

  return currentCompany?.id ? (
    <MainPage />
  ) : (
    <div className={classes.layout}>
      <div className={classes.container}>
        <h1>{I18n.t('SelectCompany.title')}</h1>
        <div>
          {currentAllCompany?.map((company: CompanyInfo, index: number) => {
            const isSubsidiary = !(currentAllCompany[0].id === company.id);
            return (
              <div key={index} className={classes.item}>
                <hr />
                <h3 className={classes.itemTitle}>{company.name}</h3>
                <DarkButton
                  type="button"
                  onClick={() => handleSetCurrentCompany(company, isSubsidiary)}
                  style={{ display: 'block', margin: '0 auto' }}
                >
                  <FormattedMessage id="SelectCompany.checkLogin" />
                </DarkButton>
              </div>
            );
          })}
          <div className={classes.item}>
            <hr />
            <Button variant="text" onClick={handleLogout} style={{ display: 'block', margin: '0 auto', fontSize: 'large' }}>
              <FormattedMessage id="SelectCompany.cancelLogin" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles<Theme>((theme) => ({
  layout: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  container: {
    margin: 'auto',
  },
  item: {},
  itemTitle: {
    textAlign: 'center',
  },
}));
