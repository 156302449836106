import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useHistory } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import { FormattedMessage } from "react-intl";
import { makeStyles } from '@material-ui/styles'
import { Link, useLocation } from "react-router-dom";
import { Dropdown, Accordion, Button, Image, Row, Col } from "react-bootstrap";
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltip from '@mui/material/Tooltip';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from '../redux/login/LoginAction'
import { persistor } from '../redux/store';
import { I18n } from '../utils/I18n';
import { useAuthorize } from '../redux/authorize/AuthorizeAction';

const drawerWidth = 234;
const root = process.env.PUBLIC_URL + "/";
const openedMixin = (theme) => ({
  width: drawerWidth + 1,
  overflow: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(5, 0),
  backgroundColor: 'black',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    height:'100vh',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const AnalysisOption = ['CustomerAnalysis', 'Total', 'Furniture', 'RFM']
const AdvertisingOption = ['email', 'sms']

export default function MiniDrawer({ children, accountInfo }) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const path = location.pathname;
  const [open, setOpen] = React.useState(false);
  const Api = {
    logout: () => {
      persistor.purge();
      dispatch(actions.logout())
      history.go(0)
    }
  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const CurrentURL = (request) => {
    let font
    path.startsWith(request) ? font = "700" : font = "200"
    return font
  }
  const { currentAllCompany, currentCompany } = useSelector((state) => state.auth, shallowEqual);

  const { isReadUserList, isReadAuthorizeSetting, isReadCompanySetting } = useAuthorize();

  const handleChangeCompany = (company, isSubsidiary) => {
    dispatch(actions.setCurrentCompany(company));
    dispatch(actions.setIsLoginSubsidiary(isSubsidiary));
  }

  ScrollToTop();
  React.useEffect(() => {
    setOpen(true)
  }, [])
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{
        backgroundImage: "url('/images/bar_bg.png')",
        backgroundSize: "100% 103%",//不然45會有線跑出來
        height: 80
      }}>
        <Toolbar >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              ...(open && { display: 'none' }),
            }}
          >
            <ChevronRightIcon color="warning" />
          </IconButton>
          <Col className="navbar navbar-expand-sm navbar-dark d-flex flex-row-reverse text-body" sx={{ marginTop: 20 }}>
            <Dropdown>
              <Dropdown.Toggle
                as={Button}
                variant="link"
                className="border-0 text-dark"
              >
                <Tooltip title="姓名">
                  <span style={{ color: 'white' }}>{accountInfo ? accountInfo : 'Name'}</span>
                </Tooltip>
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight >
                <Dropdown.Item eventKey="1" onClick={() => history.push('/Personal/Info')}>
                  {I18n.t('Personal.Info')}
                </Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={() => Api.logout()}>
                  登出系統
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {
              !currentAllCompany || currentAllCompany?.length <= 1 ?
              (
                <Tooltip title={currentCompany.name}>
                  <span style={{ color: 'white' }}>{currentCompany.name ? currentCompany.name : 'company'}</span>
                </Tooltip>
              )
              :
              (
                <Dropdown>
                  <Dropdown.Toggle
                    as={Button}
                    variant="link"
                    className="border-0 text-dark"
                  >
                    <Tooltip title={currentCompany.name}>
                      <span style={{ color: 'white' }}>{currentCompany.name ? currentCompany.name : 'company'}</span>
                    </Tooltip>
                  </Dropdown.Toggle>
                  <Dropdown.Menu alignRight >
                    {
                      currentAllCompany
                        ?.filter(value => value.id !== currentCompany.id)
                        ?.map((value, index) => {
                          const isSubsidiary = !(currentAllCompany[0].id === value.id);
                          return (
                            <Dropdown.Item
                              key={index}
                              eventKey={index}
                              onClick={() => handleChangeCompany(value, isSubsidiary)}
                            >
                              {value.name}
                            </Dropdown.Item>
                          )
                        })
                    }
                  </Dropdown.Menu>
                </Dropdown>
              )
            }

          </Col>
        </Toolbar>
      </AppBar> {/*   top */}
      <Drawer variant="permanent" open={open} >
        <DrawerHeader
          sx={{
            backgroundImage: "url('/images/logo.png')",
            backgroundSize: '70%',
            height: 60,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}>
          <div className={classes.GoldLine} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon color="warning" />
          </IconButton>
        </DrawerHeader>
        <Row>
          <div className={classes.NavigationBar} />
          <div style={{ width: '94%', whiteSpace: "nowrap", height: "87vh" }} > {/*  WIDTH會影響到SCROLLBAR */}
            <ul className="navbar-nav  scroll scroll-1 " style={{ paddingTop: '10px', overflowX: 'hidden' }} >
              <Accordion>
                {/* <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="systemAnalysis"
                  onClick={handleDrawerOpen}
                  style={{
                    fontWeight: CurrentURL("/SystemAnalysis"),
                    paddingLeft: "8px",
                    color: '#FFF',
                    backgroundColor: 'transparent',
                  }}
                  className="nav-link border-0 rounded-0 w-100 d-flex justify-content-start align-items-center"
                >
                  <Tooltip title="系統資訊"  >
                    <Link
                      to="/SystemAnalysis"
                      className="nav-link pl-1"
                      style={{ color: '#FFF', }}
                    >
                      <Image
                        src={root + "icon/dashboard.png"}
                        style={{ marginRight: "16px" }}
                      />
                      <FormattedMessage id="mainPage.dashboard" />
                    </Link>
                  </Tooltip>
                </Accordion.Toggle> */}

                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="dataanalysismenu"
                  onClick={handleDrawerOpen}
                  style={{
                    fontWeight: CurrentURL("/Analysis"),
                    paddingLeft: "8px",
                    color: '#FFF',
                    backgroundColor: 'transparent',
                  }}
                  className="nav-link border-0 rounded-0 w-100 d-flex justify-content-start align-items-center"
                >
                  <Tooltip title="數據分析"  >
                    <Link
                      to="/Analysis/CustomerAnalysis"
                      className="nav-link pl-1"
                      style={{ color: '#FFF', }}
                    >
                      <Image
                        src={root + "icon/analysis.png"}
                        style={{ marginRight: 18, width: 32 }}
                      />
                      <FormattedMessage id="mainPage.analysis" />
                    </Link>
                  </Tooltip>
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey="dataanalysismenu"
                  style={{ marginLeft: "34px" }}
                >
                  <ul className="navbar-nav">
                    {
                      AnalysisOption.map((data, index) => {
                        return (
                          <li className="nav-item d-flex justify-content-between" key={index}>
                            <Link
                              to={"/Analysis/" + data}
                              className="nav-link pl-1"
                              style={{ color: "#fff", marginLeft: 10, }}
                            >
                              <FormattedMessage id={"analysis." + data} />
                            </Link>
                            {path === ("/Analysis/" + data) ? (
                              <span style={{ color: "#fff" }}>◄</span>
                            ) : null}
                          </li>
                        )
                      })
                    }
                  </ul>
                </Accordion.Collapse>

                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="List"
                  onClick={handleDrawerOpen}
                  style={{
                    fontWeight: CurrentURL("/Customer"),
                    paddingLeft: "8px",
                    color: '#FFF',
                    backgroundColor: 'transparent',
                  }}
                  className="nav-link border-0 rounded-0 w-100 d-flex justify-content-start align-items-center"
                >
                  <Tooltip title="會員管理"  >
                    <Link
                      to="/Customer/List"
                      className="nav-link  pl-1"
                      style={{ color: "#fff" }}
                    >
                      <Image
                        src={root + "icon/customers.png"}
                        style={{ marginRight: 19, width: 30 }}
                      />
                      <FormattedMessage id="mainPage.membership" />
                    </Link>
                  </Tooltip>
                </Accordion.Toggle>

                {
                  isReadUserList || isReadAuthorizeSetting ? (
                    <>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        onClick={handleDrawerOpen}
                        eventKey="navimenu"
                        style={{
                          fontWeight: CurrentURL("/Users"),
                          paddingLeft: "8px",
                          color: '#FFF',
                          backgroundColor: "transparent",
                        }}
                        className="nav-link border-0 rounded-0 w-100 d-flex justify-content-start align-items-center"
                      >
                        <Tooltip title="企業管理"  >
                          <div
                            className="nav-link pl-1"
                            style={{ color: "#fff" }}
                          >
                            <Image
                              src={root + "icon/Merchant.png"}
                              style={{ marginRight: 20, width: 30 }}
                            />
                            <FormattedMessage id="mainPage.users" />

                          </div>
                        </Tooltip>
                      </Accordion.Toggle>
                      <Accordion.Collapse
                        eventKey="navimenu"
                        style={{ marginLeft: "34px" }}
                      >
                        <ul className="navbar-nav">
                          {
                            isReadUserList ? (
                            <li className="nav-item d-flex justify-content-between">
                              <Link
                                to={"/Users/UserList"}
                                className="nav-link pl-1"
                                style={{ color: "#fff", marginLeft: 10, }}
                              >
                                <FormattedMessage id={"mainPage.users.list"} />
                              </Link>
                              {path === ("/Users/UserList") ? (
                                <span style={{ color: "#fff" }}>◄</span>
                              ) : null}
                            </li>
                            ) : null
                          }

                          {/* {
                            isReadAuthorizeSetting ? (
                              <li className="nav-item d-flex justify-content-between">
                                <Link
                                  to={"/Users/PermissionSetting"}
                                  className="nav-link pl-1"
                                  style={{ color: "#fff", marginLeft: 10, }}
                                >
                                  <FormattedMessage id={"mainPage.users.permission"} />
                                </Link>
                                {path === ("/Users/PermissionSetting") ? (
                                  <span style={{ color: "#fff" }}>◄</span>
                                ) : null}
                              </li>
                            ) : null
                          } */}
                        </ul>
                      </Accordion.Collapse>
                    </>
                  ) : null
                }

                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="analysismenu"
                  onClick={handleDrawerOpen}
                  style={{
                    fontWeight: CurrentURL("/Advertising"),
                    paddingLeft: "4px",
                    color: '#FFF',
                    backgroundColor: 'transparent',
                  }}
                  className="nav-link border-0 rounded-0 w-100 d-flex justify-content-start align-items-center"
                >
                  <Tooltip title="廣告行銷"  >
                    <Link
                      to="/Advertising/email"
                      style={{ color: "#fff" }}
                      className="nav-link pl-1"
                    >

                      < MarkAsUnreadIcon fontSize='large' style={{ color: 'white', marginRight: 18 }} />
                      <FormattedMessage id="mainPage.advertising" />
                    </Link>
                  </Tooltip>
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey="analysismenu"
                  style={{ marginLeft: "34px" }}
                >
                  <ul className="navbar-nav">
                    {
                      AdvertisingOption.map((data, index) => {
                        return (
                          <li className="nav-item d-flex justify-content-between" key={index}>
                            <Link
                              to={"/Advertising/" + data}
                              className="nav-link pl-1"
                              style={{ color: "#fff", marginLeft: 8 }}
                            >
                              <FormattedMessage id={"advertising." + data} />
                            </Link>
                            {path === ("/Advertising/" + data) ? (
                              <span style={{ color: "#fff" }}>◄</span>
                            ) : null}
                          </li>
                        )
                      })
                    }
                  </ul>
                </Accordion.Collapse>

                {
                  isReadCompanySetting ? (
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="SettingPage"
                      onClick={handleDrawerOpen}
                      style={{
                        fontWeight: CurrentURL("/SettingPage"),
                        paddingLeft: "8px",
                        color: '#FFF',
                        backgroundColor: 'transparent',
                      }}
                      className="nav-link border-0 rounded-0 w-100 d-flex justify-content-start align-items-center"
                    >
                      <Tooltip title="公司設置" placement="bottom" >
                        <Link
                          to="/SettingPage"
                          className="nav-link"
                          style={{ fontWeight: CurrentURL("/SettingPage"), color: '#FFF', }}
                        >
                          <Image
                            src={root + "icon/setting.png"}
                            style={{ marginRight: 21, width: 32 }}
                          />
                          <FormattedMessage id="mainPage.setting" />
                        </Link>
                      </Tooltip>
                    </Accordion.Toggle>
                  ) : null
                }

              </Accordion>
            </ul>
          </div>
        </Row>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box >
  );
}

const useStyles = makeStyles((theme) => ({
  NavigationBar: {
    backgroundImage: 'url("' + root + 'images/selectbackground.png")',
    width: 254,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    textAlign: "center",
    position: 'absolute',
    height: "100vh",
    zIndex: -1
  },
  GoldLine: {
    backgroundImage: 'url("' + root + 'images/goldline.png")',
    backgroundRepeat: 'repeat',
    backgroundSize: '100%',
    width: '100%',
    top: 80,
    position: 'absolute',
    height: "0.4vh",
    zIndex: 999
  },
}))

function ScrollToTop() {
  const { pathname } = useLocation();
  const scroll = () => {
    document.getElementById('scroller').scrollTo({ top: 0, behavior: 'smooth' })
  }
  React.useEffect(() => {
    scroll()
  }, [pathname])
  return null;
}