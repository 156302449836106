import React, { useState } from 'react'
import { Form, } from "react-bootstrap";
import { I18n } from "../../../utils/I18n";
import AlertDialog from "../../../utils/AlertDialog";
import { FormattedMessage } from "react-intl";
export default function ForgetPassword() {
  const [email, setEmail] = useState('');
  return (
    <Form>
      <Form.Group controlId="email">
        <Form.Control
          type="email"
          placeholder={I18n.t("loginPage.email")}
          size="lg"
          value={email}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        />
      </Form.Group>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => {
          AlertDialog.show(
            I18n.t("msg.forgetPwd.Title"),
            I18n.t("msg.forgetPwd.SendMailDone", {
              email: email,
            })
          );
        }}
      >
        <FormattedMessage id="loginPage.sendResetPwd" />
      </button>
    </Form>
  )

}