import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
export default function SearchAppBar({ thenPage }) {
  return (
    <Box sx={{ height: '60px'}}>
      <AppBar position="static" color="inherit">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, fontWeight: '700' }}
          >
            {thenPage}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
