import React from 'react';
import { injectIntl } from 'react-intl';

import { Modal } from 'react-bootstrap';

const I18n = {
  /**
   * @param { string } id
   */
  t: function (id:any, values = {}) { return id; }
};

class I18nService extends React.Component {
  constructor(props:any) {
    super(props);
    I18n.t = function (id, values = {}) {
      return props.intl.formatMessage({ id: id }, values);
    }
  }

  render() {
    return (
      <Modal>
        {this.props.children}
      </Modal>
    );
  }
}

const I18nComponent = injectIntl(I18nService);
export { I18n, I18nComponent };