import { createSlice } from '@reduxjs/toolkit'

export const UserSlice = createSlice({
  name: 'users',
  initialState: {
    user: {},
  },
  reducers: {

    setUser: (state, action) => {
      state.user = action.payload
    },
  },
})

export const { setUser } = UserSlice.actions;

export const setUserDataAsync = data => dispatch => {
  dispatch(setUser(data));
};
export default UserSlice.reducer;