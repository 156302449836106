import React, { useState, useEffect } from 'react'
import {LoginForm} from './LoginForm'
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import * as actions from "../../../redux/login/LoginAction";
import Swal from 'sweetalert2'
interface InitValue{
  account: string,
  password: string,
}
const initValue:InitValue = {
  account: '',
  password: '',
}
const validationSchema = Yup.object().shape({
  account: Yup.string()
    .required("請輸入帳號"),
  password: Yup.string()
    .min(1, "最少輸入1碼")
    .max(20, "最多輸入20碼")
    .required("請輸入密碼"),
})
interface ApiConfig {
  login: (values: { account: string, password: string }) => void;
}
export default function LoginCard() {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const Api: ApiConfig = {
    login: (values) => {
      dispatch(actions.login({ account: values.account, password: values.password }, setErrorMessage))
    },
  };
  useEffect(() => {
    if (errorMessage) {
      Swal.fire({
        icon: 'success',
        title: errorMessage,
        showConfirmButton: false,
        timer: 1500
      })
      setErrorMessage('')
    }
  }, [errorMessage])
  return (
      <LoginForm Api={Api} initValue={initValue} validationSchema={validationSchema} />
  )
}
export type {ApiConfig,InitValue}