import React,{FC} from "react";
import { Button, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { makeStyles } from '@material-ui/styles'
import { Formik} from 'formik';
import {ApiConfig,InitValue}from './LoginCard'
import { Theme } from '@material-ui/core';
type Props={
  Api: ApiConfig,
  initValue:InitValue,
  validationSchema:any,
}
const  LoginForm:FC<Props>=({Api,initValue,validationSchema})=> {
  const classes = useStyles();
  return (
    <Formik
      initialValues={initValue}
      validationSchema={validationSchema}
      onSubmit={(values:{account:string,password:string}) => {
        Api.login(values)
      }}>
      {({ handleSubmit, handleChange, touched, errors, }) => (
        <Form noValidate>
          <Form.Group controlId="account">
            <Form.Control
              type="account"
              placeholder={'帳號'}
              size="sm"
              className={classes.LoginField}
              name="account"
              onChange={handleChange}
              isValid={touched.account && !errors.account}
            />
            {touched.account && <span style={{ color: 'red' }}>{errors.account}</span>}
          </Form.Group>
          <Form.Group controlId="pwd">
            <Form.Control
              type="password"
              placeholder={'密碼'}
              size="sm"
              name='password'
              className={classes.LoginField}
              onChange={handleChange}
              isValid={touched.password && !errors.password}
            />
            {touched.password && <span style={{ color: 'red' }}>{errors.password}</span>}
          </Form.Group>
          <Button
            variant="outline-warning"
            className={classes.LoginButton}
            size="sm"
            onClick={() => handleSubmit()}
          >
            <FormattedMessage id="loginPage.login" />
          </Button>
          <br />
          <Row className="m-0 justify-content-between">
            <Form.Check
              type="checkbox"
              id="formBasicCheckbox"
              style={{ color: 'white' }}
              label={'記住密碼'}
              custom
            />
            <Link to="/ForgetPwd" className="text-secondary">
              <div style={{ color: 'white' }}>忘記密碼</div>
            </Link>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  LoginButton: {
    backgroundColor: 'transparent',
    fontWeight: 600,
    color: 'white',
    borderRadius: 10,
    borderWidth: 1.5,
    borderColor: 'gold',
    width: '100%',
    marginBottom: 20
  },
  LoginField: {
    fontWeight: 600,
    borderWidth: 1.5,
    borderColor: 'gold'
  }
}))
export {LoginForm}