import React from 'react'
import LoginCard from './LoginCard'
import ForgetPassword from "./ForgetPassword";
import { Image, Row } from "react-bootstrap";
import { Switch, Route } from "react-router-dom";
import { makeStyles } from '@material-ui/styles'
import { FormattedMessage } from "react-intl";
import { Theme } from '@material-ui/core';
const root = process.env.PUBLIC_URL + "/";
 interface StyleProps {
  height: number;
  width: number;
}
type HookProps = {
  setHeight: (number: number) => void;
  setWidth: (number: number) => void;
}
function ListenResize(props: HookProps) {
  React.useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerHeight;
      const newWidth = window.innerWidth;
      props.setHeight(newHeight);
      props.setWidth(newWidth)
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions)
  }, [props])
}
function Login() {
  const [height, setHeight] = React.useState(window.innerHeight);
  const [width, setWidth] = React.useState(window.innerWidth);
  const classes = useStyles({ height, width });
  ListenResize({ setHeight, setWidth });
  return (
    <Row className={classes.Background} >
      <div className={classes.GoldLineLeft} />
      <div className={classes.GoldLineRight} />
      <div className={["mx-auto p-3", classes.FormBackground].join(' ')} >
        <div
          className="col-xl-12 pt-2"
          style={{ height: 200 }}
        >
          <Image
            src={root + "images/signin/logoTitle.png"}
            className={classes.LoginTitle}
          />
        </div>
        <div className="col-xl-12  p-5" >
          <Switch>
            <Route path="/ForgetPwd">
              <ForgetPassword />
            </Route>
            <Route path="/">
              <LoginCard />
            </Route>
          </Switch>
        </div>
      </div>
      <div className={classes.FooterAivLogoContainer}>
        <div className={classes.FooterAivLogin}/>
      </div>
      <footer className="col-12 text-light p-2">
        <Row className="col-12" style={{ fontSize: "20px" }}>
          <b style={{ position: "absolute", left: "30px" }}>
            <FormattedMessage id="global.appName" />
          </b>
          <div className="mx-auto">
            <FormattedMessage id="global.copyright" />
          </div>
        </Row>
      </footer>
    </Row >
  )
}
export default function LoginPage() {
  return (
    <Login />
  )
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  LoginTitle: {
    width: '100%',
    height: '100%',
    // position: 'absolute', top: "30%", left: "20%"
  },
  FooterAivLogoContainer: ({ height, width }) => ({
    position: 'absolute',
    display: (height < 500) || (width < 1000) ? 'none' : '',
    right: 50,
    height: 80,
    bottom: 0,
    width: 250,
    padding: '10px 0',
  }), FormBackground: {
    backgroundImage: 'url("' + root + 'images/signin/login_bg2.png")',
    marginTop: "2%",
    height: 500,
    width: 400,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }, Background: {
    height: "100%",
    backgroundImage: 'url("' + root + 'images/signin/login_bg.png")',
    backgroundPosition: '100% 100%',
    backgroundSize: 'cover',
    minHeight: '100vh'
  }, GoldLineLeft: ({ height }) => ({
    backgroundImage: 'url("' + root + 'images/signin/goldleft.png")',
    display: (height < 500) ? 'none' : '',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    left: window.innerHeight * 0.02,
    bottom: '20px',
    width: 200,
    height: 205,
  }),
  GoldLineRight: ({ height }) => ({
    backgroundImage: 'url("' + root + 'images/signin/goldright.png")',
    display: (height < 500) ? 'none' : '',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    right: '2%',
    top: '2%',
    width: 200,
    height: 205,
  }),
  FooterAivLogin: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundImage: 'url("' + root + 'images/signin/landscapeLogo.png")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
  }
}))