import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserListPageState {
  userList: Array<Array<any>>;
}

const initialState = {
  userList: []
};

export const userListPageSlice = createSlice({
  name: 'userListPageSlice',
  initialState: initialState as UserListPageState,
  reducers: {
    setUserList: (state, action: PayloadAction<Array<Array<any>>>) => {
      state.userList = action.payload;
    },
    addUser: (state, action: PayloadAction<Array<any>>) => {
      state.userList = [...state.userList, action.payload]
    }
  },
});

export default userListPageSlice;
