import axios from 'axios';
const AppSetting = process.env.REACT_APP_URL;

export default class CompanyProtocol {
  static pIns = new CompanyProtocol();
  static ins() { return this.pIns; }

  getSelfCompanyInfo(callback) {
    axios.get(`${AppSetting}/company/info`)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in getSelfCompanyInfo:", error));
  }

  getCompanyInfo(companyId, callback) {
    axios.get(`${AppSetting}/company/${companyId}/info`)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in getCompanyInfo:", error));
  }

  updateCompanyInfo(name, description, adminEmail, companyId, callback) {
    axios({
      url: `${AppSetting}/company/${companyId}/info`,
      headers: {
        "Content-Type": "application/json",
      },
      method: 'put',
      data: JSON.stringify({
        name: name,
        description: description,
        adminEmail: adminEmail,
      })
    })
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in updateCompanyInfo:", error));
  }

  getSelfCompanySubsidiary(callback) {
    axios.get(`${AppSetting}/company/subsidiary`)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in getCompanySubsidiary:", error));
  }

  getCompanySubsidiary(companyId, callback) {
    axios.get(`${AppSetting}/company/${companyId}/subsidiary`)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in getCompanySubsidiary:", error));
  }

  getSelfCompanyRoleList(callback) {
    axios.get(`${AppSetting}/company/permission/role/list`)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in getCompanyRoleList:", error));
  }

  getCompanyRoleList(companyId, callback) {
    axios.get(`${AppSetting}/company/${companyId}/permission/role/list`)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in getCompanyRoleList:", error));
  }

  getSelfCompanyAuthorityList(callback) {
    axios.get(`${AppSetting}/company/permission/authority/list`)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in getSelfCompanyAuthorityList: 勾選選項錯誤: ", error));
  }

  getEmailSentTimes(callback){
    axios.get(`${AppSetting}/company/emailSentTimes`)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in getEmailSentTimes:", error));
  }

  getSmsSentTimes(callback){
    axios.get(`${AppSetting}/company/smsSentTimes`)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in getSmsSentTimes:", error));
  }

  addSelfCompanyRole(name, authorities, callback) {
    axios({
      url: `${AppSetting}/company/permission/role`,
      headers: {
        "Content-Type": "application/json",
      },
      method: 'post',
      data: JSON.stringify({
        "name": name,
        "authorities": authorities,
      })
    })
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in addSelfCompanyRole:", error));
  }

  addCompanyRole(companyId, name, authorities, callback) {
    axios({
      url: `${AppSetting}/company/${companyId}/permission/role`,
      headers: {
        "Content-Type": "application/json",
      },
      method: 'post',
      data: JSON.stringify({
        "name": name,
        "authorities": authorities,
      })
    })
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in addCompanyRole:", error));
  }

  updateSelfCompanyRole(roleId, name, authorities, callback) {
    axios({
      url: AppSetting + "/company/permission/role/" + roleId,
      headers: {
        "Content-Type": "application/json",
      },
      method: 'put',
      data: JSON.stringify({
        "name": name,
        "authorities": authorities,
      })
    })
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in updateSelfCompanyRole:", error));
  }

  updateCompanyRole(companyId, roleId, name, authorities, callback) {
    axios({
      url: `${AppSetting}/company/${companyId}/permission/role/${roleId}`,
      headers: {
        "Content-Type": "application/json",
      },
      method: 'put',
      data: JSON.stringify({
        "name": name,
        "authorities": authorities,
      })
    })
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in updateCompanyRole:", error));
  }

  deleteSelfCompnayRole(roleId, callback) {
    axios.delete(AppSetting + "/company/permission/role/" + roleId)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in deleteSelfCompnayRole:", error));
  }

  deleteCompnayRole(companyId, roleId, callback) {
    axios.delete(`${AppSetting}/company/${companyId}/permission/role/${roleId}`)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in deleteCompnayRole:", error));
  }

  getSelfCompanyAccount(callback) {
    axios.get(`${AppSetting}/company/account`)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in getSelfCompanyAccount:", error));
  }

  getCompanyAccount(companyId, callback) {
    axios.get(`${AppSetting}/company/${companyId}/account`)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in getCompanyAccount:", error));
  }

  updateSelfComanyAccountStatus(userId, status, callback) {
    axios({
      url: AppSetting + "/company/account/" + userId,
      headers: {
        "Content-Type": "application/json",
      },
      method: 'put',
      data: JSON.stringify({
        "status": status
      })
    })
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in updateSelfComanyAccountStatus:", error));
  }

  updateComanyAccountStatus(companyId, userId, status, callback) {
    axios({
      url: `${AppSetting}/company/${companyId}/account/${userId}`,
      headers: {
        "Content-Type": "application/json",
      },
      method: 'put',
      data: JSON.stringify({
        "status": status
      })
    })
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in updateComanyAccountStatus:", error));
  }

  updateSelfComanyAccountRole(userId, roleId, callback) {
    axios({
      url: AppSetting + "/company/account/" + userId,
      headers: {
        "Content-Type": "application/json",
      },
      method: 'put',
      data: JSON.stringify({
        "roleId": roleId
      })
    })
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in updateSelfComanyAccountRole:", error));
  }

  updateComanyAccountRole(companyId, userId, roleId, callback) {
    axios({
      url: `${AppSetting}/company/${companyId}/account/${userId}`,
      headers: {
        "Content-Type": "application/json",
      },
      method: 'put',
      data: JSON.stringify({
        "roleId": roleId
      })
    })
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in updateComanyAccountRole:", error));
  }

  getCompanyUserAccount(userId, callback) {
    fetch(AppSetting + "/company/account/" + userId, { credentials: 'include' })
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in getCompanyUserAccount:", error));
  }

  addSelfCompanyUserAccount(newAccount, callback) {
    axios({
      url: AppSetting + "/company/account",
      headers: {
        "Content-Type": "application/json",
      },
      method: 'post',
      data: JSON.stringify(newAccount)
    })
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in addSelfCompanyAccount: 新增使用者錯誤: ", error));
  }

  addCompanyUserAccount(companyId, newAccount, callback) {
    axios({
      url: AppSetting + "/company/" + companyId + "/account",
      headers: {
        "Content-Type": "application/json",
      },
      method: 'post',
      data: JSON.stringify(newAccount)
    })
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in addCompanyAccount: 新增使用者錯誤: ", error));
  }

  updateSelfComanyUserAccount(userId, newAccount, callback) {
    axios({
      url: AppSetting + "/company/account/" + userId,
      headers: {
        "Content-Type": "application/json",
      },
      method: 'put',
      data: JSON.stringify(newAccount)
    })
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in updateSelfComanyUserAccount:", error));
  }

  updateComanyUserAccount(companyId, userId, newAccount, callback) {
    axios({
      url: `${AppSetting}/company/${companyId}/account/${userId}`,
      headers: {
        "Content-Type": "application/json",
      },
      method: 'put',
      data: JSON.stringify(newAccount)
    })
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in updateComanyUserAccount:", error));
  }

  deleteSelfComanyUserAccount(userId, callback) {
    axios.delete(AppSetting + "/company/account/" + userId)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in deleteSelfComanyUserAccount:", error));
  }

  deleteComanyUserAccount(companyId, userId, callback) {
    axios.delete(`${AppSetting}/company/${companyId}/account/${userId}`)
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in deleteComanyUserAccount:", error));
  }

}
