import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../rootReducer';
import { authorizeSlice } from './AuthorizeSlice';

export const useAuthorize = () => {
  const { isReadUserList, isReadAuthorizeSetting, isReadCompanySetting } = useSelector((state: RootState) => state.authorize);
  const dispatch = useDispatch();

  const setIsReadUserList = (isRead: boolean) => {
    dispatch(authorizeSlice.actions.setIsReadUserList(isRead));
  };

  const setIsReadAuthorizeSetting = (isRead: boolean) => {
    dispatch(authorizeSlice.actions.setIsReadAuthorizeSetting(isRead));
  };

  const setIsReadCompanySetting = (isRead: boolean) => {
    dispatch(authorizeSlice.actions.setIsReadCompanySetting(isRead));
  };

  return { isReadUserList, isReadAuthorizeSetting, isReadCompanySetting, setIsReadUserList, setIsReadAuthorizeSetting, setIsReadCompanySetting };
};
