import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customers: [],
  isCustomerListLoaded: false,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const CustomerManagementListSlice = createSlice({
  name: "CustomerManagementListSlice",
  initialState: initialState,
  reducers: {
    getCustomerList: (state, action) => {
      state.customers = action.payload.customers;
    },
    setIsCustomerListLoaded: (state, action) => {
      state.isCustomerListLoaded = action.payload;
    }
  }
});
