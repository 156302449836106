import AlertDialog from '../../utils/AlertDialog';
import { I18n } from '../../utils/I18n';

class Result {
  static OK             = 0;
  static CONNECT_ERROR  = -1;
  //......
}

function ShowResultAlertDialog(rs) {
  if(rs === Result.OK){
    return;
  }
  switch(rs){
    case Result.CONNECT_ERROR:
      AlertDialog.show(I18n.t('msg.title.error'), I18n.t("msg.net.connectErr"));
      break;
    default:
      AlertDialog.show(I18n.t('msg.title.error'), I18n.t("msg.sys.unknowErr"));
      break;
  }
}

export { Result, ShowResultAlertDialog };