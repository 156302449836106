import CompanyProtocol from '../../net/CompanyProtocol';
import UserProtocol from '../../net/UserProtocol';
import { actions } from './LoginSlice';
import { setUserDataAsync } from '../UserSlice';

export interface CompanyInfo {
  id: number;
  name: string;
  description: string;
  adminEmail: string;
  address: string;
  subsidiaries?: Array<CompanyInfo>;
}

export const login = (request: object, setErrorMessage: any) => async (dispatch: any) => {
  const res = await UserProtocol.ins().login(request);
  const {
    status,
    data: { data: session },
  } = res;
  if (status === 200) {
    dispatch(accountInfo());
    dispatch(actions.login({ session: session }));
    setErrorMessage('登入成功');
  }
};
export const accountInfo = () => async (dispatch: any) => {
  try {
    UserProtocol.ins().getAccountInfo((status: number, result: { data: string }) => {
      if (status === 200) {
        dispatch(setUserDataAsync(result.data));
        dispatch(actions.updateUser({ user: result.data }));
        return;
      }
    });
  } catch (error: any) {
    if (error.response) {
      dispatch(actions.login({ user: '' }));
      return;
    }
  }
};

export const logout = () => (dispatch: any) => {
  return UserProtocol.ins()
    .logout()
    .then((response) => {
      dispatch(actions.logout());
    });
};

export const getCurrentAllCompany = () => (dispatch: any) => {
  CompanyProtocol.ins().getSelfCompanySubsidiary((result: { resultCode: string; data: CompanyInfo }) => {
    const { data } = result;
    const allCompany = [data, ...(data.subsidiaries ?? [])];
    dispatch(actions.getCurrentAllCompany(allCompany));
  });
};

export const setCurrentCompany = (CompanyInfo: CompanyInfo) => (dispatch: any) => {
  dispatch(actions.setCurrentCompany(CompanyInfo));
};

export const setIsLoginSubsidiary = (isSubsidiary: boolean) => (dispatch: any) => {
  dispatch(actions.setIsLoginSubsidiary(isSubsidiary));
};

export const getCompanyInfo = (isLoginSubsidiary: boolean, currentCompany: CompanyInfo, callback: Function) => (dispatch: any) => {
  if (isLoginSubsidiary) CompanyProtocol.ins().getCompanyInfo(currentCompany.id, callback);
  else CompanyProtocol.ins().getSelfCompanyInfo(callback);
};

export const getEmailSentTimes = ()=>(dispatch:any)=>{
  CompanyProtocol.ins().getEmailSentTimes((result: { resultCode: string; data: number })=>{
    const { data } = result;
    dispatch(actions.setCurrentCompanyEmailSentTimes(data));
  });
}

export const getSmsSentTimes = ()=>(dispatch:any)=>{
  CompanyProtocol.ins().getSmsSentTimes((result: { resultCode: string; data: number })=>{
    const { data } = result;
    dispatch(actions.setCurrentCompanySmsSentTimes(data));
  });
}
