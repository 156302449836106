import { lazy } from 'react'
import { RouteConfig } from 'react-router-config'
const EventPage = lazy(() => import('./Event/EventPage'))
const Dashboard = lazy(() => import('./users/New/Dashboard'))
const PBIAnalysisPage = lazy(() => import('./PBIAnalysisPage'))
const PIBPage = lazy(() => import('./PIBPage'))
const Organization = lazy(() => import('./Organization'))
const FoodMenu = lazy(() => import('./FoodMenu'))
const SettingPage = lazy(() => import('./SettingPage'))
const UserListPage = lazy(() => import('./users/UserListPage'))
const CreateUser = lazy(() => import('./users/CreateUser'))
const PersonalInfo = lazy(() => import('./Personal/PersonalInfo'))
const PermissionSetting = lazy(() => import('./users/PermissionSetting'))
const AdvertisingPage = lazy(() => import('./Advertising/AdvertisingPage'))
const CustomerPage = lazy(() => import('./CustomerManagementList/CustomerPage'))

const Analysis = [{
  path: '/Analysis/rfm',
  component: PIBPage,
},
{
  path: '/Analysis/furniture',
  component: PIBPage,
},
{
  path: '/Analysis/Total',
  component: PIBPage,
},
{
  path: '/Analysis/CustomerAnalysis',
  component: PBIAnalysisPage
},]
const Advertising = [{
  path: '/Advertising/email',
  component: AdvertisingPage
},
{
  path: '/Advertising/sms',
  component: AdvertisingPage
},]
const User = [{
  path: '/Users/UserList',
  component: UserListPage
},
{
  path: '/Users/CreateUser',
  component: CreateUser
},
{
  path: '/Users/PermissionSetting',
  component: PermissionSetting
},]
const Other = [{
  path: '/Organization',
  component: Organization
},
{
  path: '/FoodMenu',
  component: FoodMenu
},
{
  path: '/SettingPage',
  component: SettingPage
},
{
  path: '/Customer',
  component: CustomerPage,
},
{
  path: '/SystemAnalysis',
  component: Dashboard,
},]
const Event = [{
  path: '/Event',
  component: EventPage,
},]
const Personal = [{
  path: '/Personal/Info',
  component: PersonalInfo
}]
const Default = [{
  path: '/',
  component: PBIAnalysisPage,
}]
const routes: RouteConfig[] = [
  ...User,
  ...Other,
  ...Event,
  ...Analysis,
  ...Advertising,
  ...Personal,
  ...Default,
];


export default routes;