import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import setupAxios from './redux/setupAxios'
import axios from 'axios';
import store, { persistor } from "./redux/store";
import { IntlProvider } from 'react-intl';
import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nComponent } from './utils/I18n';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AlertDialog from './utils/AlertDialog';
import EventMocks from './mocks/EventkMocks'
import mockAxios from './mocks/mockAxios';
const zh_TW = require("./langs/zh-tw.json");
const locale = navigator.language;
//TODO : select lang file on multi langs
var messages = zh_TW;
setupAxios(axios, store);
EventMocks();
// mockAxios(axios);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<div>等一下啦</div>}>
        <IntlProvider locale={locale} key={locale} messages={messages}>
          <I18nComponent />
          <AlertDialog />
          <App />
        </IntlProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
