import { FaArrowCircleUp } from 'react-icons/fa';
import { Button } from './ScrollToTopButtonStyle';

const ScrollButton = ({ visible }) => {
  const scrollToTop = () => {
    document.getElementById('scroller').scrollTo({ top: 0, behavior: 'smooth' })
  };
  return (
    <Button>
      <FaArrowCircleUp onClick={scrollToTop}
        style={{ display: visible ? 'inline' : 'none', opacity: 0.9 }} />
    </Button>
  );
}

export default ScrollButton;