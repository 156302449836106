import { combineReducers } from 'redux';
import * as auth from './login/LoginSlice';
import userReducer from './UserSlice';
import { CustomerManagementListSlice } from '../screens/CustomerManagementList/_Redux/CustomerManagementListSlice';
import { advertisingSlice } from '../screens/Advertising/_Redux/AdvertisingSlice';
import * as ActivitySlice from '../screens/Event/Activity/_Redux/ActivitySlice';
import { advertisingViewSlice } from '../screens/Event/View/_Redux/ViewSlice';
import { userListPageSlice } from '../screens/users/_Redux/userListPage/userListPageSlice';
import { permissionSettingSlice } from '../screens/users/_Redux/permissionSetting/permissionSettingSlice';
import { authorizeSlice } from './authorize/AuthorizeSlice';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  users: userReducer,
  advertising: advertisingSlice.reducer,
  customer: CustomerManagementListSlice.reducer,
  event: ActivitySlice.reducer,
  view: advertisingViewSlice.reducer,
  userListPage: userListPageSlice.reducer,
  permissionSetting: permissionSettingSlice.reducer,
  authorize: authorizeSlice.reducer,
});
export type RootState = ReturnType<typeof rootReducer>;
export function* rootSaga() {}
