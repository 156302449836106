import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  AdvertisingField: {},
  ExcelFormat: {},
  TamplatesData: [],
  TamplatesDataContent:"",
  variableName: [],
  dataValueList: [],
  SystemParams: []
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const advertisingSlice = createSlice({
  name: "Advertising",
  initialState: initialState,
  reducers: {
    readExcel: (state, action) => {
      state.AdvertisingField = Object.assign({}, state.AdvertisingField, action.payload.AdvertisingField)
      state.ExcelFormat = action.payload.ExcelFormat;
    },
    getEmailTemplates: (state, action) => {
      if (action.payload.TamplatesData === '') {
        state.TamplatesData = [];
      } else {
        state.TamplatesData = action.payload.TamplatesData;
      }
    },
    getRawTemplateContent: (state, action) => {
      if (action.payload.TamplatesDataContent === 'FAIL') {
        state.TamplatesDataContent = "";
      } else {
        state.TamplatesDataContent = action.payload.TamplatesDataContent;
      }
    },
    getSystemParam: (state, action) => {
      let SystemParams = action.payload.SystemParams;
      SystemParams.map(data => state.AdvertisingField["_DEFAULT_" + data.id] = { [data.key]: data.value })
    },
    deleteTemplates: (state, action) => {
      state.TamplatesData = state.TamplatesData.filter(data => data.id !== action.payload.TamplatesDataId)
    },
    cleanTamplatesDataContent: (state) => {
      state.TamplatesDataContent = ""
    }
  }
});
