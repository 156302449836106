import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Role {
  id: number;
  name: string;
  authorities: Array<any>;
}

export interface PermissionSettingState {
  selectRole: Role;
}

const initialState = {
  selectRole: {} as Role,
};

export const permissionSettingSlice = createSlice({
  name: 'permissionSettingSlice',
  initialState: initialState as PermissionSettingState,
  reducers: {
    setSelectRole: (state, action: PayloadAction<Role>) => {
      state.selectRole = action.payload;
    },
    increaseSelectRoleAuthorities: (state, action: PayloadAction<Array<any>>) => {
      state.selectRole.authorities = [...state.selectRole.authorities, ...action.payload];
    },
    setSelectRoleAuthorities: (state, action: PayloadAction<Array<any>>) => {
      state.selectRole.authorities = action.payload;
    },
  },
});

export default permissionSettingSlice;
