import Swal from 'sweetalert2';
import { I18n } from '../utils/I18n';
export function useSwalAlert() {
  const alert403 = () => Swal.fire({ icon: 'error', title: I18n.t('msg.warning'), text: I18n.t('msg.error.403') });
  const alert401 = () => Swal.fire({ icon: 'warning', title: I18n.t('msg.warning'), text: I18n.t('msg.error.401') });
  const alert400 = () => Swal.fire({ icon: 'error', title: I18n.t('msg.warning'), text: I18n.t('msg.error.400') });
  const alertSuccess = (request) => Swal.fire({ icon: 'success', title: request.title ? request.title : '成功', text: request.text });
  const alertError = (request) => Swal.fire({ icon: 'error', title: request.title ? request.title : '錯誤', text: request.text });
  return {
    alert400,
    alert401,
    alert403,
    alertSuccess,
    alertError,
  };
}
