import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  getUserByToken: '[getUserByToken] Action',
  updateUser: '[updateUser] Action',
  getCurrentAllCompany: '[getCurrentAllCompany] Action',
  setCurrentCompany: '[setCurrentCompany] Action',
  setIsLoginSubsidiary: '[setIsLoginSubsidiary] Action',
  setCurrentCompanyEmailSentTimes: '[setCurrentCompanyEmailSentTimes] Action',
  setCurrentCompanySmsSentTimes: '[setCurrentCompanySmsSentTimes] Action',
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  currentAllCompany: null,
  currentCompany: {},
  isLoginSubsidiary: false,
};

export const reducer = persistReducer(
  {
    storage,
    key: 'quickTrade-auth',
    whitelist: ['authToken', 'user', 'certifiactionNumber', 'currentCompany', 'currentAllCompany', 'isLoginSubsidiary'],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        return {
          authToken: action.payload.session,
          user: action.payload.user,
        };
      }
      case actionTypes.Logout: {
        return {
          ...initialAuthState,
        };
      }
      case actionTypes.updateUser: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.getCurrentAllCompany: {
        return { ...state, currentAllCompany: action.payload };
      }
      case actionTypes.setCurrentCompany: {
        return { ...state, currentCompany: action.payload };
      }
      case actionTypes.setIsLoginSubsidiary: {
        return { ...state, isLoginSubsidiary: action.payload };
      }
      case actionTypes.setCurrentCompanyEmailSentTimes: {
        return { ...state, currentCompany: { ...state.currentCompany, emailSentTimes: action.payload } };
      }
      case actionTypes.setCurrentCompanySmsSentTimes: {
        return { ...state, currentCompany: { ...state.currentCompany, smsSentTimes: action.payload } };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  login: (session, user) => ({
    type: actionTypes.Login,
    payload: session,
    user,
  }),
  logout: () => ({
    type: actionTypes.Logout,
  }),
  updateUser: (user) => ({
    type: actionTypes.updateUser,
    payload: user,
  }),
  getCurrentAllCompany: (currentAllCompany) => ({
    type: actionTypes.getCurrentAllCompany,
    payload: currentAllCompany,
  }),
  setCurrentCompany: (companyInfo) => ({
    type: actionTypes.setCurrentCompany,
    payload: companyInfo,
  }),
  setIsLoginSubsidiary: (isSubsidiary) => ({
    type: actionTypes.setIsLoginSubsidiary,
    payload: isSubsidiary,
  }),
  setCurrentCompanyEmailSentTimes: (times) => ({
    type: actionTypes.setCurrentCompanyEmailSentTimes,
    payload: times,
  }),
  setCurrentCompanySmsSentTimes: (times) => ({
    type: actionTypes.setCurrentCompanySmsSentTimes,
    payload: times,
  }),
};
