import React, { FC, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from './screens/users/Auth/LoginPage';
import ResetPwd from './screens/ResetPwd';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useSwalAlert } from './components/SwalAlert';
import { RootState } from './redux/rootReducer';
import * as actions from './redux/login/LoginAction'
import Cutscenes from './utils/Cutscenes';
import "./scrollBar.css";
import "./App.css";
import { isBrowser } from "react-device-detect";
import ActivityViewPage from './screens/Event/View/ActivityViewPage';
import SelectCompany from './screens/users/Auth/SelectCompany';
const AppSetting = process.env.REACT_APP_URL;

export const App: FC = () => {
  const token: any = useSelector<RootState>(({ auth }) => auth.authToken, shallowEqual)
  const isAuthorized: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)
  const [CutscenesControl, setCutscenesControl] = useState<boolean>(false);
  React.useEffect(() => {
    setTimeout(() => setCutscenesControl(false), 4500)
  }, [])
console.log(token,'token')
  ErrorMessageDecide();
  return (
    <Router>
      <Switch>
        <Route path="/ResetPwd">
          <ResetPwd />
        </Route>
        <Route path="/Event/View">
          <ActivityViewPage />
        </Route>
        <Route path="/">
          {
            !CutscenesControl ?
              <div >
                {isBrowser ? (isAuthorized ? <SelectCompany /> : <LoginPage />) : <div>請使用電腦登入</div>}
              </div>
              :
              <Cutscenes />
          }
        </Route>
      </Switch>
    </Router>

  );
}
function ErrorMessageDecide() {
  const dispatch = useDispatch();
  const { alert400, alert401, alert403, alertError } = useSwalAlert();
  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    let res = error.request;
    if (res.status >= 400) {
      if (res.responseURL && res.responseURL === `${AppSetting}/login`) {
        alertError({ text: "帳號或密碼輸入錯誤" });
        return error;
      }
      switch (res.status) {
        case (400):
          alert400();
          break;
        case (401):
          if (res.response === "User account is locked!") {
            alertError({ text: "錯誤次數過多帳戶已上鎖" });
            return error;
          } else {
            alert401();
            dispatch(actions.logout());
          }
          break;
        case (403):
          alert403();
          break;
        default:
          throw error;
      }
      return error
    }
  });
}
