import React, { Suspense, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { renderRoutes, RouteConfig } from 'react-router-config';
import routes from './RouteSetting';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { RootState } from '../redux/rootReducer';
import AppBar from '../components/AppBar';
import Drawer from '../components/Drawer';
import ScrollToTopButton from '../components/ScrollToTopButton';
import { useAuthorize } from '../redux/authorize/AuthorizeAction';
export default function MainPage() {
  const [thenPage, setThenPage] = useState<String>('');
  const [visible, setVisible] = useState<Boolean>(false);
  const location = useLocation();
  const path = location.pathname;
  React.useEffect(() => {
    SwitchBarPath(path, setThenPage);
  }, [path]);
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual);
  const isLoginSubsidiary: boolean = useSelector<RootState>(({ auth }) => auth.isLoginSubsidiary, shallowEqual) as boolean;
  const currentCompany: any = useSelector<RootState>(({ auth }) => auth.currentCompany, shallowEqual);
  const { isReadUserList, isReadAuthorizeSetting, isReadCompanySetting, setIsReadUserList, setIsReadAuthorizeSetting, setIsReadCompanySetting } =
    useAuthorize();

  const handleScroll = (request: any) => {
    if (request.target.scrollTop > 200) {
      setVisible(true);
    } else if (request.target.scrollTop <= 200) {
      setVisible(false);
    }
  };

  const [nowRoutes, setNowRoutes] = useState<RouteConfig[]>([]);

  const setIsRead = (authList: any) => {
    if (isLoginSubsidiary) {
      setIsReadUserList(authList?.includes('ShowSubsidiaryRole') && authList?.includes('ShowSubsidiaryAccount'));
      setIsReadAuthorizeSetting(authList?.includes('ShowSubsidiaryRole') && authList?.includes('ShowAuthority'));
    } else {
      setIsReadUserList(authList?.includes('ShowRole') && authList?.includes('ShowCompanyAccount'));
      setIsReadAuthorizeSetting(authList?.includes('ShowRole') && authList?.includes('ShowAuthority'));
    }

    setIsReadCompanySetting(authList?.includes('ShowCompanyInformation'));
  };

  const setRoutes = () => {
    const newRoutes: RouteConfig[] = routes.reduce((prev: RouteConfig[], current: RouteConfig) => {
      const path = current.path as string;
      const newPath = path?.match(/\/[a-zA-Z]+/g);
      if (!newPath) {
        prev.push(current);
        return prev;
      }

      switch (newPath[0].toString()) {
        case '/Users':
          if (path === '/Users/UserList' && isReadAuthorizeSetting) prev.push(current);
          if (path === '/Users/PermissionSetting' && isReadUserList) prev.push(current);
          break;
        case '/SettingPage':
          if (isReadCompanySetting) prev.push(current);
          break;
        default:
          prev.push(current);
          break;
      }

      return prev;
    }, []);

    setNowRoutes(newRoutes);
  };

  React.useEffect(() => {
    const { authList } = user;
    if (authList?.includes('Admin')) {
      setNowRoutes(routes);
    } else {
      setIsRead(authList);
      setRoutes();
    }
  }, [user, currentCompany]);

  return (
    <Drawer accountInfo={user.name}>
      <AppBar thenPage={thenPage} />
      <Box
        className="scroll scroll-1  pl-2 pr-2 pt-4"
        id="scroller"
        style={{
          height: '87vh',
          backgroundColor: '#F0F0F0',
          width: '100%',
        }}
        onScroll={handleScroll}
      >
        <Suspense
          fallback={
            <Box sx={{ width: '100%' }}>
              <Skeleton style={{ height: 100 }} />
              <Skeleton style={{ height: 100 }} animation="wave" />
              <Skeleton style={{ height: 100 }} />
              <Skeleton style={{ height: 100 }} animation="wave" />
              <Skeleton style={{ height: 100 }} animation={false} />
            </Box>
          }
        >
          {renderRoutes(nowRoutes)}
        </Suspense>
        <ScrollToTopButton visible={visible} />
      </Box>
    </Drawer>
  );
}

function SwitchBarPath(path: string, setThenPage: any) {
  const newPath = path.match(/\/[a-zA-Z]+/g);
  if (!newPath) {
    return;
  }
  switch (newPath[0].toString()) {
    case '/SystemAnalysis':
      return setThenPage('系統資訊');
    case '/Analysis':
      setThenPage('數據分析');
      break;
    case '/Customer':
      setThenPage('會員管理');
      break;
    case '/Users':
      setThenPage('企業管理');
      break;
    case '/Advertising':
      setThenPage('廣告行銷');
      break;
    case '/SettingPage':
      setThenPage('公司設置');
      break;
    case '/Personal':
      setThenPage('個人資料');
      break;
    default:
      break;
  }
}
