import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export default class ResetPwd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      newPwd: '',
      checkPwd: '',
      showMsgModal: false,
      modalTitle: '',
      modalMsg: '',
    };

  }

  componentDidMount () {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get('token');
    if(token != null){
      this.setState({
        token
      });
    }
  }

  render() {
    return (
      <div className="row align-items-end" style={{height: '100vh', backgroundImage: 'url(/images/login_bg.jpg)'}}>
        <div className="col-12">
          <div className="col-xl-3 mx-auto rounded-lg bg-secondary text-light p-5">
            <h2 className="text-center">變更密碼</h2>
            <div className="form-group">
              <label htmlFor="pwd">請輸入新密碼:</label>
              <input type="password" className="form-control" id="pwd" value={this.state.newPwd} onChange={(e)=>this.setState({newPwd: e.target.value})} />
            </div>
            <div className="form-group">
              <label htmlFor="pwd2">請再輸入一次新密碼:</label>
              <input type="password" className="form-control" id="pwd2" value={this.state.checkPwd} onChange={(e)=>this.setState({checkPwd: e.target.value})} />
            </div>
            <button type="button" className="btn btn-primary" onClick={()=>{
              //for test
              if(this.state.newPwd === ''){
                this.showMsgModal('重置密碼', '請輸入您要設置的新密碼！');
                return;
              }else if(this.state.newPwd !== this.state.checkPwd){
                this.showMsgModal('重置密碼', '兩次輸入的密碼不相同！');
                return;
              }
              this.showMsgModal('重置密碼', '系統已將您的密碼重新設定，請回到首頁進行登入。');
            }}>重置密碼</button>
          </div>
        </div>
        { this.renderMsgModal() }
        <footer className="col-12 text-light bg-primary">維酷數據分析系統</footer>
      </div>
    );
  }

  renderMsgModal(){
    return (
      <Modal show={this.state.showMsgModal} onHide={() => this.setState({ showMsgModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p dangerouslySetInnerHTML={{ __html: this.state.modalMsg }}></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.setState({ showMsgModal: false })}>確定</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  /**
   * @param {String} title 
   * @param {String} msg 
   */
  showMsgModal (title, msg) {
    this.setState({
      showMsgModal: true,
      modalTitle: title,
      modalMsg: msg,
    });
  }
}