import { Result, ShowResultAlertDialog } from './base/Result';
import { I18n } from '../utils/I18n';
import AlertDialog from '../utils/AlertDialog';
import axios from 'axios';
const AppSetting = process.env.REACT_APP_URL;

export default class UserProtocol {
  static pIns = new UserProtocol();
  static ins() { return this.pIns; }

  login(request) {
    const formData = new FormData();
    formData.append("username", request.account);
    formData.append("password", request.password);
    return axios.post(AppSetting + "/account/login", formData);
  }

  logout() {
    return axios.delete(AppSetting + "/logout");
  }

  getAccountInfo(callback) {
    axios.get(AppSetting + "/account/info")
      .then((res) => callback(res.status, res.data))
      .catch(error => console.log("error in getAccountInfo:", error));
  };

  getDashboardInfo(callback) {
    axios.get(AppSetting + "/announcement/list")
      .then(res => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in getDashboardInfo:", error));
  }

  getReportInfo() {
    axios({
      url: AppSetting + "/system/issue/report",
      method: 'post',
    })
      .then(res => res.data)
      .then(
        (result) => {
        },
        (error) => {
          console.log("error in report:", error)
        }
      )
  };

  addIssueReport(title, body, callback) {
    axios({
      url: AppSetting + "/system/issue/report",
      headers: {
        "Content-Type": "application/json",
      },
      method: 'post',
      data: JSON.stringify({
        title: title,
        body: body
      })
    })
      .then((res) => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in addIssueReport:", error));
  }

  getIssueList() {
    axios.get(AppSetting + "/system/issue/list")
      .then(res => res.data)
      .then(
        (result) => {
        },
        (error) => {
          console.log("error in IssueList:", error)
        }
      )
  };


  addAccount(loginId, password, name, phone, email, UserRole, callback) {
    axios({
      url: AppSetting + "account/create",
      headers: {
        "Content-Type": "application/json",
      },
      method: 'post',
      data: JSON.stringify({
        loginId: loginId,
        password: password,
        name: name,
        phone: phone,
        email: email,
        UserRole: UserRole
      })
    })
      .then((res) => res.data)
      .then(result => callback(result))
      .catch(error => {
        console.error("error in addAccount:", error);
        AlertDialog.show(I18n.t("msg.createUser.ReplyPost"), I18n.t("msg.createUser.ReplyFail"));
      });
  }

  updateAccount(loginId, password, newPassword, name, phone, email, UserRole, callback) {
    axios({
      url: AppSetting + "/account/edit",
      headers: {
        "Content-Type": "application/json",
      },
      method: 'post',
      data: JSON.stringify({
        loginId: loginId,
        password: password,
        newPassword: newPassword,
        name: name,
        phone: phone,
        email: email,
        roleId: UserRole
      })
    })
      .then((res) => res.data)
      .then(result => callback(result))
      .catch(error => {
        console.error("error in updateAccount:", error);
        AlertDialog.show(I18n.t("msg.PersonalInfo.PersonalInfo"), I18n.t("msg.PersonalInfo.UpdateInfo"));
      });
  }

  updateAccountLocale(language, callback) {
    axios({
      url: AppSetting + "/account/edit",
      headers: {
        "Content-Type": "application/json",
      },
      method: 'post',
      data: JSON.stringify({
        locale: language,
      })
    })
      .then((res) => res.data)
      .then(result => callback(result))
      .catch(error => console.log("error in updateAccountLocale:", error));
  }

  reqGetUserList(groupId, callback) {
    this.onGetUserListCallback = callback;
    //for test
    let CompanyTree = [
      {
        GroupId: 1,
        GroupName: "AIV餐飲集團",
        Brand: [
          {
            BrandId: 1,
            BrandName: "中華一番鍋物",
            Store: [
              {
                StoreId: 1,
                StoreName: "信義區1號分店"
              },
              {
                StoreId: 2,
                StoreName: "信義區2號分店"
              },
              {
                StoreId: 3,
                StoreName: "南港車站分店"
              }
            ]
          },
          {
            BrandId: 2,
            BrandName: "滿漢全席中華料理",
            Store: [
              {
                StoreId: 4,
                StoreName: "台北車站微風店"
              },
              {
                StoreId: 5,
                StoreName: "南港車站分店"
              }
            ]
          }
        ]
      },
      {
        GroupId: 2,
        GroupName: "滿漢傳奇餐飲集團",
        Brand: [
          {
            BrandId: 3,
            BrandName: "菊下樓",
            Store: [
              {
                StoreId: 6,
                StoreName: "四川總店"
              },
              {
                StoreId: 7,
                StoreName: "陽泉酒家分店"
              }
            ]
          }
        ]
      }
    ];
    let UserListData = [
      ["WKT0123456789", "Kunder_Lin", "AIV餐飲集團\\中華一番鍋物\\南港車站分店", "行銷部", 3, "kunder_lin@aivalue.com.tw", 1],
      ["WKT0123456788", "elegant_chen", "AIV餐飲集團\\中華一番鍋物\\南港車站分店", "管理部", 3, "kunder_lin@aivalue.com.tw", 0],
      ["WKT0123456787", "呂嘉仁", "AIV餐飲集團\\中華一番鍋物\\南港車站分店", "人事部", 2, "kunder_lin@aivalue.com.tw", -1],
      ["WKT0123456786", "西野加奈", "AIV餐飲集團\\中華一番鍋物\\南港車站分店", "行銷部", 1, "kunder_lin@aivalue.com.tw", 1],
      ["WKT0123456785", "劉昴星", "滿漢傳奇餐飲集團\\菊下樓\\陽泉酒家分店", "研發部", 1, "kunder_lin@aivalue.com.tw", 1]
    ];
    let resp = {
      Company: CompanyTree,
      UserList: UserListData
    };
    this.onGetUserList(Result.OK, resp);
  }

  onGetUserList(rs, resp) {
    if (rs !== Result.OK) {
      ShowResultAlertDialog(rs);
      return;
    }
    this.onGetUserListCallback(resp.Company, resp.UserList);
  }

  onGetPersonalInfo(rs, resp) {
    if (rs !== Result.OK) {
      ShowResultAlertDialog(rs);
      return;
    }
    this.onGetPersonalInfoCallback(resp);
  }
}