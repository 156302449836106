import axios from 'axios';
import MockAdapter from "axios-mock-adapter";
const AppSetting = process.env.REACT_APP_URL;
const mock = new MockAdapter(axios, { onNoMatch: 'passthrough' });
var eventData = {
  datas: [{
    activityCode: 1,
    title: "測試1",
    name: "John Smith",
    startTime: "2000-08-03",
    endTime: "2000-08-04",
    place: "基隆",
    signupCount: 5,
    checkCount: 1,
    status: "checked",
  }, {
    activityCode: 2,
    title: "測試2",
    name: " Smith",
    startTime: "2000-08-03",
    endTime: "2000-08-04",
    place: "基隆",
    signupCount: 5,
    checkCount: 1,
    status: "checked",
  }],
}
export default function EventMocks() {
  mock.onPost(AppSetting + "Event/ActivityData").reply(
    () => {
      return [200, eventData];
    }
  );
  mock.onPost(AppSetting + "Event/SubmitEventForm").reply(
    (config) => {
      let data = JSON.parse(config.data).Creator;
      let newData = {};
      newData.activityCode = 3;
      newData.title = 'test';
      newData.name = "恩"
      newData.startTime = data.startTime
      newData.endTime = data.endTime
      newData.address = data.address
      newData.peoplea = 10;
      newData.peopleb = 6;
      newData.status = 'checked';
      eventData.datas.push(newData)
      return [200, eventData.datas];
    }
  );
  mock.onPost(AppSetting + "Event/getActivityManageData/1").reply(200, {
    datas: {
      Creator: {
        address: '基隆',
        startTime: '2000-08-03',
        endTime: '2000-08-04',
        guide: 'https://yahoo.com.tw',
        numberOfTickets: 10
      },
      Questions: {
        id: 156,
        Title: '我是大標題大吧',
        questions: [
          {
            id: 2, title: '小標題',
            options: [
              { type: 'field', content: 'test' },
              { type: 'field', content: 'test1' },
              { type: 'field', content: 'test2' }
            ]
          },
          {
            id: 4, title: '小標題',
            options: [
              { type: 'radio', content: 'test' },
              { type: 'radio', content: 'test1' },
              { type: 'radio', content: 'test2' }
            ]
          }, {
            id: 2, title: '小標題',
            options: [
              { type: 'check', content: 'test' },
              { type: 'check', content: 'test1' },
              { type: 'check', content: 'test2' },
              { type: 'check', content: 'test2' },
              { type: 'check', content: 'testdasdfasdfssssssssssssssssssssssssssssssfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffss2' },
            ]
          }, {
            id: 4, title: '小標題',
            options: [
              { type: 'radio', content: 'test' },
              { type: 'radio', content: 'test1' },
              { type: 'radio', content: 'test2' }
            ]
          }, {
            id: 2, title: '小標題',
            options: [
              { type: 'shortAnswer', content: 'test' }
            ]
          },
        ]
      }
    }
  }
  );
  //------------------------------------------------------------View------------------------------------------------------------
  mock.onPost(AppSetting + "View/getActivityDataImgId/1").reply(200, { datas: 6 })
  mock.onPost(AppSetting + "View/getActivityData/1").reply(200,
    {
      datas: {
        id: 156,
        Title: '我是大標題大吧',
        questions: [
          {
            id: 2, title: '小標題',
            options: [
              { type: 'field', content: 'test' },
              { type: 'field', content: 'test1' },
              { type: 'field', content: 'test2' }
            ]
          },
          {
            id: 4, title: '小標題',
            options: [
              { type: 'radio', content: 'test' },
              { type: 'radio', content: 'test1' },
              { type: 'radio', content: 'test2' }
            ]
          }, {
            id: 2, title: '小標題',
            options: [
              { type: 'check', content: 'test' },
              { type: 'check', content: 'test1' },
              { type: 'check', content: 'test2' },
              { type: 'check', content: 'test2' },
              { type: 'check', content: 'testdasdfasdfssssssssssssssssssssssssssssssfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffss2' },
            ]
          }, {
            id: 4, title: '小標題',
            options: [
              { type: 'radio', content: 'test' },
              { type: 'radio', content: 'test1' },
              { type: 'radio', content: 'test2' }
            ]
          }, {
            id: 2, title: '小標題',
            options: [
              { type: 'shortAnswer', content: 'test' }
            ]
          },
        ]
      }
    }
  );
}


// mock.onPost("/IpCheck/ActivityData", { params: { searchText: "John" } }).reply(200, {
//   users: [{ id: 1, name: "John Smith" }],
// });